import { TJobsSchemaTab, TMegaMenuCard, TMegaMenuContext } from "#typesmegaMenu";
import { jobsSchema } from "@constants*";
import { MegaMenuContext } from "@context*";
import { ELandingType } from "@enumssearchHit.enum ";
import { MegaMenuRepository } from "@repositories*";
import { get } from "lodash";
import { basePath } from "@constantscompanyLinks.constant";
import { TFunction } from "next-i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

type TProps = {
  t: TFunction;
};

const useJobsHeader = ({ t }: TProps) => {
  const { setMegaMenuContext, ...jobsContext } = useContext(MegaMenuContext);
  const [selectedSideMenu, setSelectedSideMenu] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isRequested, setIsRequested] = useState(false);

  const selectedKey = useMemo(() => Object.keys(jobsSchema)[selectedTab], [selectedTab]);
  const currentType: ELandingType = useMemo(
    () => jobsSchema[selectedKey].type ?? ELandingType.BY_LOCATION,
    [selectedKey]
  );

  const handleSelectedTab = useCallback((index: number) => {
    setSelectedTab(index);
    setIsRequested(false);
    setSelectedSideMenu(0);
  }, []);

  const onSelectCard = useCallback((index: number) => {
    setSelectedSideMenu(index);
  }, []);

  useEffect(() => {
    const contextData: TMegaMenuCard[] = get(jobsContext, selectedKey);
    const getJobs = async () => {
      const jobs = await MegaMenuRepository.getJobsByType(currentType);
      setMegaMenuContext((current: TMegaMenuContext) => ({ ...current, [selectedKey]: jobs }));
    };

    if (contextData.length <= 0 && !isRequested) {
      setIsRequested(true);
      getJobs();
    }
  }, [currentType, isRequested, jobsContext, selectedKey, selectedTab, setMegaMenuContext]);

  return {
    jobAction: { label: t("header:userMenu.jobs") },
    allJobsAction: { label: t("header:drawerMenu.allJobs"), url: `${basePath}/empleos` },
    onChangeTab: handleSelectedTab,
    tabs: Object.keys(jobsSchema).map((key) => {
      const { slug, adapter }: TJobsSchemaTab = get(jobsSchema, key);
      const jobs: TMegaMenuCard[] = get(jobsContext, key);
      return adapter({ jobs, slug, t, selected: selectedSideMenu, onSelectCard, key });
    })
  };
};

export default useJobsHeader;
