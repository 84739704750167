import React from "react";
import { MagnetoUIProvider } from "magneto365.ui";
import { EDeviceTypes } from "@enums/filter.core.enum";
import withCandidateHoc from "@componentscandidate/hoc";
import MagnetoHeader from "./magnetoHeader";

type TProps = {
  device: EDeviceTypes;
};

const MagnetoUIHeader: React.FC<TProps> = ({ device }) => {
  return (
    <MagnetoUIProvider device={device}>
      <MagnetoHeader />
      <div id="magneto-signup-v2-modal" className="magneto-signup-v2-modal" data-button-create="no" />
    </MagnetoUIProvider>
  );
};

export default withCandidateHoc(MagnetoUIHeader);
