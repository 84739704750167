import React, { useContext } from "react";
import Image from "next/image";
import { ReferredContext } from "@context";
import { TReferralSites } from "#types/referred";
import style from "./referredHeaderType.module.scss";
import Description from "@components/landing/cardDescription/description.wrap";

const HeaderReferred = () => {
  const { referralSites } = useContext(ReferredContext);
  const { title, description, logoImage, backgroundImage } = referralSites as TReferralSites;

  const hasDescription = Boolean(description);
  const hasTitle = Boolean(title);

  return (
    <header className={style["header-referred"]}>
      <div className={style.banner}>
        <div className={style.container}>
          <div className={style.image}>
            <Image src={logoImage} alt="logo" width={300} height={43} objectFit="contain" loading="lazy" />
          </div>
        </div>
      </div>
      <div className={style["slogan-container"]}>
        <div className={style.slogan}>
          <div className={`${style["slogan-image"]} ${hasDescription || hasTitle ? style["with-overlay"] : ""}`}>
            <Image
              src={backgroundImage}
              alt="background"
              layout="fill"
              objectFit="cover"
              quality={100}
              priority={true}
            />
          </div>
          <div className={style["slogan-content"]}>
            <h2>{title}</h2>
            {description && <Description description={description} />}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderReferred;
