import React, { Fragment } from "react";
import { MegaMenu } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import { useMegaMenu } from "@hooks/magnetoUI";
import { TRequestSearch } from "#typesfilter.core";
import withFiltersHoc from "@componentsfilters/withFiltersHoc";

type TProps = {
  t: TFunction;
};

type ComposedProps = TProps & {
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const MagnetoHeader: React.FC<ComposedProps> = ({ setFilters, appliedFilters, t }) => {
  const megaMenuProps = useMegaMenu({ t, setFilters, appliedFilters });
  return (
    <Fragment>
      <MegaMenu {...megaMenuProps} />
    </Fragment>
  );
};

export default withTranslation(["header", "routes"])(withFiltersHoc<TProps>(MagnetoHeader));
