import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "#types/api.core";
import { TCandidate } from "#types/candidate";
import { isClientSide } from "@constants/environment.constant";
import { getToken } from "@utils/auth";
import Api from "@core/api.core";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { profileApiHost } = getConfig().publicRuntimeConfig;

export class CandidateRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    candidate: {
      profile: "/v1/candidate",
      verify: "/v1/candidate-terms/verify",
      acceptTerms: "/v1/candidate-terms"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: profileApiHost }, baseOptions));
    this.api.interceptors.request.use(this.useBearerToken);
  }

  private useBearerToken(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    if (initConfig.headers && initConfig.headers["Authorization"]) return initConfig;
    if (isClientSide && initConfig.headers) {
      initConfig.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return initConfig;
  }

  async getProfile(): Promise<TCandidate> {
    return this.get<TCandidate>(this.endpoints.candidate.profile).then((response) => response.data);
  }

  async checkTerms(): Promise<boolean> {
    return this.get<boolean>(this.endpoints.candidate.verify).then((response) => response.data);
  }

  async acceptTerms() {
    return this.post(this.endpoints.candidate.acceptTerms);
  }
}

export default new CandidateRepository();
