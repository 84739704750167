import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IListMenuItems, ILogoutJobsTemplate, MenuItemInfo } from "magneto365.ui";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";

import Facebook from "@public/assets/icons/facebook-new.svg";
import Instagram from "@public/assets/icons/instagram.svg";
import Linkedin from "@public/assets/icons/linkedin.svg";
import Web from "@public/assets/icons/sitio-web.svg";
import Twitter from "@public/assets/icons/twitter.svg";
import Youtube from "@public/assets/icons/youtube.svg";

import { TRequestSearch } from "#typesfilter.core";
import { GeneralMenuSettings, TLanding } from "#typeslanding";
import {
  fullBasePath,
  jobsHostBase,
  LegacyProfile,
  loggedUserHost,
  oauthFrontHost
} from "@constantsenvironment.constant";
import { AuthContext, CandidateContext, JobsContext, LandingContext } from "@contextlanding.context";
import { useSearch } from "@hooksuseSearch";
import { HEADER_LANDING } from "@schemas/landing/header.schema";
import {
  MAGNETO_LOGIN_HEADER_SCHEMA,
  MAGNETO_LOGOUT_HEADER_SCHEMA
} from "@schemaslayout/header/magnetoUIHeader.schema";
import useSignupWidget from "@utilshooks/useSignupWidget";
import { renameLandingPath } from "@utilsstrings/renameLandingPath.util";
import { fillUrlUtms, getUrlSignIn } from "@utilsurl";
import {
  formatBreadcrumbText,
  generateBreadCrumbs,
  generateBreadCrumbUrl
} from "../../utils/strings/generateBreadCrumbs.util";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const useLandingHeader = ({ t, onClose, setFilters, appliedFilters }: TProps) => {
  const { selectedJob, onDetailJobSection, jobDetailAction } = useContext(JobsContext);
  const router = useRouter();
  const [urlSingIn, setUrlSingIn] = useState("");
  const { query } = useRouter();
  const candidateContext = useContext(CandidateContext);

  const currentCandidateLng = candidateContext.candidate?.country?.iso2?.includes("MX") ? "MX" : "CO";
  const countryPath = `${jobsHostBase}/${currentCandidateLng.toLowerCase()}`;
  const logoutPath = `${oauthFrontHost}/candidates/oauth2/logout`;

  const { landingPage, settingsJobSite } = useContext(LandingContext);

  const { isAuthenticated } = useContext(AuthContext);

  const { loading, onClickHandle } = useSignupWidget({ t, onClose });

  const searchbarOnSearch = useSearch({ t, setFilters, appliedFilters });

  const handleLogout = useCallback(() => {
    router.push(logoutPath);
  }, [logoutPath, router]);

  const gotSettings = useCallback(() => {
    router.push(`${loggedUserHost}/profile/resume?tab`);
  }, [router]);

  const generateUrl = useCallback(
    (markSlug: string, isPage: boolean) => {
      if (isPage) return t<string>("routes:landing", { companySlug: markSlug });
      return t<string>("routes:marks", { companySlug: query.companySlug, brand: markSlug });
    },
    [t, query.companySlug]
  );

  const homeUrl = `${jobsHostBase}/home`;

  const socialMedia = landingPage?.companySocialMedia;

  const companySlug = landingPage?.companyName;

  const { generalMenuSettings: options } = landingPage as TLanding;

  const logo = options.colorLogoUrl;

  const gif = options.gifUrl;

  const urlWithUtms = useMemo(() => {
    return fillUrlUtms(`${fullBasePath}${t<string>("routes:landing", { companySlug: query.companySlug })}`, query);
  }, [t, query]);

  const onRedirect = useCallback(
    (url: string, baseUrl: string) => {
      const path = t<string>(url, { companySlug: query.companySlug });
      return fillUrlUtms(`${baseUrl}${path}`, query);
    },
    [t, query]
  );

  const companyUrl = urlWithUtms;

  const { urlParam, urlBreadcrumText } = useMemo(() => {
    const [pathname] = router.asPath.split("?");
    return { urlParam: pathname, urlBreadcrumText: pathname.replace(/-/gi, " ") };
  }, [router.asPath]);

  useEffect(() => {
    let urlSingIn;
    if (!onDetailJobSection) {
      urlSingIn = getUrlSignIn({
        jobSlug: "",
        action: jobDetailAction,
        route: router.route,
        t
      });
    } else {
      urlSingIn = getUrlSignIn({
        jobSlug: selectedJob?.jobSlug,
        action: jobDetailAction,
        route: router.route,
        t
      });
    }

    setUrlSingIn(urlSingIn);
  }, [jobDetailAction, onDetailJobSection, router.route, selectedJob?.jobSlug, t]);

  const headerJobsLandingProps = useMemo(() => {
    const searchbarProps = {
      termValue: router.query?.q || "",
      placeholder: t(MAGNETO_LOGOUT_HEADER_SCHEMA.searchbar.placeholder),
      onSearch: searchbarOnSearch
    };

    const mobileSearchbarProps = {
      termValue: router.query?.q || "",
      onSearch: searchbarOnSearch
    };

    const SignInProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink,
      text: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink.text),
      href: urlSingIn
    };

    const SignUpProps = {
      ...MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton,
      buttonText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton.buttonText),
      onClick: onClickHandle,
      loadingState: loading
    };

    const brands = settingsJobSite?.marks.map(({ logoMark, markName, markSlug, mainBrandPage }) => {
      return {
        brandImg: logoMark,
        brandAlt: markSlug,
        brandTitle: markName,
        brandUrl: fillUrlUtms(generateUrl(markSlug, mainBrandPage), query)
      };
    });

    const ourBrandsText = t("registration:landing.links.marks");

    const profileImage = {
      userImage: candidateContext.candidate?.profileImageFileName || null
    };

    const MenuList: MenuItemInfo[] = HEADER_LANDING.map(({ tag, url, baseUrl }) => {
      const item = options[tag as keyof GeneralMenuSettings];
      return {
        text: item ? item.toString() : t(`registration:landing.links.${tag}`),
        url: onRedirect(url, baseUrl),
        slug: t(url, { companySlug: query.companySlug })
      };
    });

    const listMenuProps: IListMenuItems = {
      menuList: MenuList,
      urlParam
    };

    const dinamycUrl = (url: string, text: string) => {
      switch (text) {
        case "userMenu.help":
          return url;
        case "userMenu.allJobs":
          return `${countryPath}${t("routes:jobs")}`;
        case "userMenu.resume":
          return `${loggedUserHost}${url}`;
        default:
          return `${LegacyProfile}${url}`;
      }
    };

    const listMenuUserProps = {
      urlParam,
      haveGif: gif ? true : false,
      menuItems: MAGNETO_LOGIN_HEADER_SCHEMA.menuUser.map((menu) => ({
        ...menu,
        title: t(menu.title),
        items: menu.items.map(({ text, url, icon, slug, ...rest }) => ({
          ...rest,
          icon: icon,
          url: dinamycUrl(url, text),
          text: t(text),
          slug: text.includes("userMenu.allJobs") ? MenuList[1].slug : slug
        }))
      })),

      menuItems1440: MAGNETO_LOGIN_HEADER_SCHEMA.menuUser1440.map((menu) => ({
        items: menu.items.map(({ text, icon, ...rest }) => ({
          ...rest,
          icon: icon,
          text: t(text)
        }))
      })),

      logout: {
        logoutText: t(MAGNETO_LOGIN_HEADER_SCHEMA.logout),
        onClick: handleLogout
      },

      settings: {
        settingsText: t("userMenu.configuration"),
        onClick: gotSettings
      },

      profileImage: {
        userImage: candidateContext.candidate?.profileImageFileName || null
      },

      isAuthenticated
    };

    const ListIcon = [
      { icon: Facebook.src, url: socialMedia && socialMedia.facebookUrl },
      { icon: Instagram.src, url: socialMedia && socialMedia.instagramUrl },
      { icon: Linkedin.src, url: socialMedia && socialMedia.linkedinUrl },
      { icon: Youtube.src, url: socialMedia && socialMedia.youtubeUrl },
      { icon: Twitter.src, url: socialMedia && socialMedia.twitterUrl },
      { icon: Web.src, url: socialMedia && socialMedia.webSite }
    ];

    const breadCrumbsList = generateBreadCrumbs(urlBreadcrumText);
    const companySlugFromServer = landingPage?.mainCompanySlug as string;

    const currentMark = settingsJobSite?.marks.find(
      (mark) => mark.markSlug.toLowerCase() === (query.brand as string)?.toLowerCase()
    );

    const currentCompany = {
      slug: query.companySlug || companySlugFromServer,
      name: landingPage?.companyName
    };

    const urlFromServer = generateBreadCrumbUrl(breadCrumbsList, `${fullBasePath}/`);
    const breadCrumbFromServer = renameLandingPath(
      breadCrumbsList,
      currentCompany.slug as string,
      currentCompany.name as string,
      currentMark?.markSlug,
      currentMark?.markName
    );

    const breadcrumbProps = {
      breadcrumbText: formatBreadcrumbText(breadCrumbFromServer),
      baseUrl: `${fullBasePath}/`,
      haveRedirect: true,
      detailTitle: router.query.jobSlug ? selectedJob?.title : "",
      breadCrumbFromServer,
      urlFromServer
    };

    const jobsTabsProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListJobs.map(({ tabText, url, slug, ...rest }) => ({
        ...rest,
        slug: tabText.includes("userMenu.allJobs") ? t(`routes:${slug}`) : slug,
        url: tabText.includes("userMenu.allJobs") ? `${countryPath}${t(`routes:${url}`)}` : `${LegacyProfile}${url}`,
        tabText: t(tabText)
      })),
      addDivider: false
    };

    const processTabsProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListProcess.map(({ tabText, url, ...rest }) => ({
        ...rest,
        url: `${LegacyProfile}${url}`,
        tabText: t(tabText)
      })),
      addDivider: true
    };

    const curriculumTabProps = {
      urlParam,
      headerTabList: MAGNETO_LOGIN_HEADER_SCHEMA.headerTabListCV.map(({ tabText, url, ...rest }) => ({
        ...rest,
        url: `${loggedUserHost}${url}`,
        tabText: t(tabText)
      })),
      addDivider: true
    };

    const LoginJobsHeaderProps = {
      companyUrl,
      profileImage,
      listMenuUserProps,
      searchbar: searchbarProps,
      mobileSearchbar: mobileSearchbarProps,
      breadcrumbProps,
      homeUrl,
      companyLogo: logo,
      companySlug,
      gif,
      jobsTabsProps,
      processTabsProps,
      curriculumTabProps,
      brandMenuProps: {
        brandsProps: {
          brands,
          ourBrandsText
        },
        companyLogo: logo,
        companySlug,
        companyUrl
      }
    };

    const logoutJobsHeaderProps = {
      companyUrl,
      searchbar: searchbarProps,
      mobileSearchbar: mobileSearchbarProps,
      signInLink: SignInProps,
      signUpButton: SignUpProps,
      breadcrumbProps,
      homeUrl,
      companyLogo: logo,
      companySlug,
      gif,
      brandMenuProps: {
        brandsProps: {
          brands,
          ourBrandsText
        },
        companyLogo: logo,
        companySlug,
        companyUrl
      },
      allJobsText: t("drawerMenu.allJobs"),
      allJobsLink: MenuList[1].url
    };

    return {
      listMenuProps,
      ListIcon,
      logoutJobsHeaderProps,
      LoginJobsHeaderProps
    };
  }, [
    router.query?.q,
    router.query.jobSlug,
    t,
    searchbarOnSearch,
    urlSingIn,
    onClickHandle,
    loading,
    settingsJobSite?.marks,
    candidateContext.candidate?.profileImageFileName,
    urlParam,
    gif,
    handleLogout,
    gotSettings,
    socialMedia,
    urlBreadcrumText,
    landingPage?.mainCompanySlug,
    landingPage?.companyName,
    query,
    selectedJob?.title,
    companyUrl,
    homeUrl,
    logo,
    companySlug,
    generateUrl,
    options,
    onRedirect,
    countryPath,
    isAuthenticated
  ]);

  const { ListIcon, listMenuProps, logoutJobsHeaderProps, LoginJobsHeaderProps } = headerJobsLandingProps;

  const logoutLandingProps: ILogoutJobsTemplate = {
    homeUrl,
    ListIcon,
    listMenuProps,
    logoutJobsHeaderProps
  };

  const loginLandingProps = {
    homeUrl,
    listMenuProps,
    ListIcon,
    LoginJobsHeaderProps
  };

  return { logoutLandingProps, loginLandingProps };
};

export default useLandingHeader;
