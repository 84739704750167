import React from "react";
import { basePath, fullBasePath } from "@constantscompanyLinks.constant";
import { TMegaMenuJobTab, TMegaMenuJobsResponse } from "#typesmegaMenu";
import { MegaMenu, MegaMenuEmpty } from "magneto365.ui";
import { isMobile } from "react-device-detect";

const { Cards, SideCards } = MegaMenu;

export const jobsTabAdapter = ({ jobs, slug, t, key }: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${fullBasePath}/${slug}`;
  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty title={t(`header:jobs.empty.title`)} subtitle={t(`header:jobs.empty.subtitle`)} />
      ) : (
        <Cards
          jobs={jobs.map(({ counter, h1, slug, iconUrl, logoUrl }) => ({
            title: h1,
            quotas: counter,
            href: `${fullBasePath}/trabajos/${slug}`,
            logo: logoUrl,
            icon: iconUrl
          }))}
          maxCards={isMobile ? 15 : 40}
          action={{ label: t(`header:jobs.viewAll`), url }}
          title={t(`header:jobs.tabs.${key}`)}
          actionTitle={{ label: t(`header:jobs.actionTitle.${key}`), url }}
        />
      )
  };
};

export const jobsSideTabAdapter = ({
  jobs,
  slug,
  t,
  key,
  onSelectCard,
  selected
}: TMegaMenuJobTab): TMegaMenuJobsResponse => {
  const url = `${fullBasePath}/${slug}`;
  return {
    label: t(`header:jobs.tabs.${key}`),
    url,
    content:
      jobs.length == 0 ? (
        <MegaMenuEmpty title={t(`header:jobs.empty.title`)} subtitle={t(`header:jobs.empty.subtitle`)} />
      ) : (
        <SideCards
          wrapperProps={{
            title: t(`header:jobs.tabs.${key}`),
            actionTitle: { label: t(`header:jobs.actionTitle.${key}`), url },
            maxCards: 40,
            jobs:
              jobs.at(selected ?? 0)?.landings?.map(({ counter, h1, slug }) => ({
                title: h1,
                quotas: counter,
                href: `${fullBasePath}/trabajos/${slug}`
              })) ?? []
          }}
          sideProps={{
            maxCards: isMobile ? 15 : 12,
            jobs: jobs.map(({ h1, counter, slug }) => {
              const url = `${basePath}/${slug}`;
              return {
                title: h1,
                href: url,
                quotas: counter,
                onClick: () => ({})
              };
            }),
            onSelectCard: onSelectCard,
            action: { label: t(`header:jobs.viewAll`), url }
          }}
        />
      )
  };
};
