export enum ENotFoundType {
  "T404" = "404",
  "T500" = "500",
  "T410" = 410
}

export enum EVacantRedirectType {
  T301 = 301
}

export enum EVacancyRedirects {
  vacancyErrorRedirect = "vacancyErrorRedirect"
}
