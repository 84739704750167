import { TJobsSchema } from "#typesmegaMenu";
import { forCompanies } from "@constantscompanyLinks.constant";
import { supportZendesk } from "@constantscompanyLinks.constant";
import { frequentQuestions } from "@constantscompanyLinks.constant";
import { postJobOffer } from "@constantscompanyLinks.constant";
import { magnetoHomeNext } from "@constantscompanyLinks.constant";
import { ELandingType } from "@enumssearchHit.enum ";
import { jobsSideTabAdapter, jobsTabAdapter } from "@utilsmegaMenu/jobsByTypeAdapter.util";

export const socialHeaderTabs = [magnetoHomeNext, postJobOffer, forCompanies];
export const socialHelpOptions = [frequentQuestions, supportZendesk];

export const jobsSchema: TJobsSchema = {
  [ELandingType.BY_LOCATION]: {
    slug: "trabajos/empleos-por-ciudades",
    adapter: jobsSideTabAdapter,
    type: ELandingType.BY_LOCATION
  },
  [ELandingType.BY_OCCUPATION]: {
    slug: "trabajos/empleos-por-cargo",
    adapter: jobsSideTabAdapter,
    type: ELandingType.BY_OCCUPATION
  },
  [ELandingType.BY_SECTOR]: {
    slug: "trabajos/empleos-por-sector-laboral",
    adapter: jobsTabAdapter,
    type: ELandingType.BY_SECTOR
  },
  [ELandingType.BY_COMPANY]: {
    slug: "empresas",
    adapter: jobsTabAdapter,
    type: ELandingType.BY_COMPANY
  }
};
