import { useContext, useMemo } from "react";
import { fullBasePath, jobsHostBase } from "@constants*";
import { generateBreadCrumbs, generateBreadCrumbUrl } from "@utilsstrings/generateBreadCrumbs.util";
import { useRouter } from "next/router";
import { JobsContext } from "@context*";

const useBreadcrumb = () => {
  const router = useRouter();
  const { selectedJob } = useContext(JobsContext);

  const { urlBreadcrumText } = useMemo(() => {
    const [pathname] = router.asPath.split("?");
    return { urlParam: pathname, urlBreadcrumText: pathname.replace(/-/gi, " ") };
  }, [router.asPath]);

  const homeUrl = `${jobsHostBase}/es`;

  const breadCrumbsList = generateBreadCrumbs(urlBreadcrumText);

  const breadcrumbProps = {
    breadcrumbText: urlBreadcrumText,
    baseUrl: `${fullBasePath}/`,
    haveRedirect: true,
    detailTitle: router.query.jobSlug ? selectedJob?.title : "",
    breadCrumbFromServer: breadCrumbsList,
    urlFromServer: generateBreadCrumbUrl(breadCrumbsList, `${fullBasePath}/`)
  };
  return { homeUrl, breadcrumbProps };
};

export default useBreadcrumb;
