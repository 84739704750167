import { TRequestSearch } from "#typesfilter.core";
import { fullBasePath, loggedUserHost } from "@constants*";
import { useSearch } from "@hooksuseSearch";
import { MAGNETO_LOGOUT_HEADER_SCHEMA } from "@schemaslayout/header/magnetoUIHeader.schema";
import useSignupWidget from "@utilshooks/useSignupWidget";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const useMainHeader = ({ t, setFilters, appliedFilters }: TProps) => {
  const { onClickHandle } = useSignupWidget({ t });
  const router = useRouter();
  const searchbarOnSearch = useSearch({ t, setFilters, appliedFilters });

  const searchBarProps = {
    termValue: router.query?.q || "",
    placeholder: t(MAGNETO_LOGOUT_HEADER_SCHEMA.searchbar.placeholder),
    onSearch: searchbarOnSearch
  };

  return {
    loginProps: {
      loginText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink.text),
      loginUrl: `${loggedUserHost}/sign-in`,
      signUpText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton.buttonText),
      onClickSignUp: onClickHandle
    },
    searchBarProps,
    logoProps: {
      url: fullBasePath
    }
  };
};

export default useMainHeader;
