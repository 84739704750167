import { blog as blogUrl, socialHelpOptions } from "@constants*";
import { socialHeaderTabs } from "@constantsmegaMenu.constant";
import { TFunction } from "next-i18next";

type TProps = {
  t: TFunction;
};

const useSocialHeader = ({ t }: TProps) => {
  const blog = { label: "Blog", url: blogUrl };

  const helpAction = { label: t("header:socialHeader.help") };

  const helpOptions = (t("header:socialHeader.helpOptions", { returnObjects: true }) as Array<string>).map(
    (tab: string, index: number) => ({
      label: tab,
      url: socialHelpOptions[index]
    })
  );

  const tabs = (t("header:socialHeader.tabs", { returnObjects: true }) as Array<string>).map(
    (tab: string, index: number) => ({
      selected: index == 0,
      label: tab,
      url: socialHeaderTabs[index]
    })
  );

  return {
    helpOptions,
    helpAction,
    blog,
    tabs
  };
};

export default useSocialHeader;
