import React, { useContext, useState } from "react";
import { CandidateContext } from "@contextcandidate.context";
import { CandidateRepository } from "@repositories*";
import { Trans } from "react-i18next";
import { Form } from "antd";
import { useTranslation } from "next-i18next";
import { MagnetoUIProvider, UserTerms } from "magneto365.ui";
import style from "./userTerms.module.scss";

const { Check, Content, Highlight, Submit, Subtitle, Text, Title, UlList } = UserTerms;

const UserTermsModal = () => {
  const { t } = useTranslation("terms");

  const { termsAccepted } = useContext(CandidateContext);
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  if (termsAccepted !== false) return null;

  const acceptTerms = async () => {
    setIsLoading(true);
    CandidateRepository.acceptTerms().then(({ data }) => {
      if (data) {
        setOpen(false);
        setIsLoading(false);
      }
    });
  };

  return (
    <MagnetoUIProvider>
      <UserTerms isOpen={open} onClose={() => null}>
        <Title>{t("terms:terms.title")}</Title>
        <Subtitle>{t("terms:terms.subtitle")}</Subtitle>
        <Content>
          <Trans
            t={t}
            i18nKey="terms:terms.here"
            components={{
              a: <a style={{ color: "#47535f", textDecoration: "underline" }} />,
              h1: <Highlight />,
              section: <section />,
              strong: <strong />,
              p: <Text />,
              ul: <UlList />,
              li: <li />
            }}
          />
        </Content>
        <Form
          onFinish={(values) => {
            if (values.checked) {
              acceptTerms();
            }
          }}
        >
          <Form.Item
            name="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
                message: "campo requerido"
              }
            ]}
            valuePropName="isChecked"
          >
            <Check className={style["check-element"]} isChecked onChange={() => null}>
              {t("terms:terms.checkedTerms")}
            </Check>
          </Form.Item>
          <Submit type="submit" isLoading={isLoading}>
            {t("terms:terms.acceptTerms")}
          </Submit>
        </Form>
      </UserTerms>
    </MagnetoUIProvider>
  );
};

export default UserTermsModal;
