import React, { useContext, useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { AuthContext } from "@context";
import { useIsWidgetToApply } from "@hooks";
import { EWidgetType } from "@enums";
import { fillUrlUtms, getHomeUrl } from "@utils/url";
import MagnetoLogo from "@public/images/magneto-logo.svg";
import MagnetoIcon from "@public/images/favicon-magneto.png";
import style from "./linkLogo.module.scss";

type Props = { margin?: string; full?: boolean; small?: boolean; t: TFunction };

const LinkLogo: React.FC<Props> = ({ margin = "0", full = false, small = false, t }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);
  const { query } = useRouter();

  const target = useMemo(() => (isWidgetIframe ? "_blank" : "_self"), [isWidgetIframe]);

  return (
    <Link href={fillUrlUtms(getHomeUrl(isAuthenticated, t), query)} passHref>
      <a style={{ marginLeft: margin }} target={target} rel="noopener noreferrer">
        <span className={`${full ? style.full_logo : small ? style.small_logo : style.logo}`}>
          <MagnetoLogo width={130} height={40} />
        </span>
        <span className={`${full ? style.full_icon : small ? style.small_icon : style.icon}`}>
          <Image alt="logo" src={MagnetoIcon} width={30} height={30} loading="lazy" />
        </span>
      </a>
    </Link>
  );
};

export default withTranslation("routes")(LinkLogo);
