import { useCallback, useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { message } from "antd";
import { IFilter } from "magneto365.ui";
import { ApplicationsSingleton } from "@classes/applicationsSingleton.class";
import { AuthContext, CandidateContext, JobsContext, LandingContext } from "@context/jobs.context";
import {
  FiltersRepository,
  FiltersWithAuthRepository,
  JobsRepository,
  JobsWithAuthRepository,
  TagManagerRepository
} from "@repositories";
import {
  TFilterRepository,
  TGlobalFilterSettings,
  TRequestFilter,
  TRequestSearch,
  TSearchResponse
} from "#types/filter.core";
import { TJobRecord, TSimilarVacancies } from "#types/jobs";
import { TJobQuestions, TSendAnswers, TSourceOrigins } from "#types/detail";
import {
  EDeviceTypes,
  EQueryAction,
  EQueryParams,
  EEventsGA4,
  EVacantViews,
  EViewItemListName
} from "@enums/filter.core.enum";
import { TMagnetoUIActiveFilter, normalizeFilter } from "@utils/filters/normalizeFilters.util";
import {
  getAllUtms,
  getQueyParams,
  getSuggestedByMatcher,
  removeQueryParamByWindow,
  stateToQueryParams
} from "@utils/url";
import { fMagnetoAdapter, isPublicJobsiteAdapter } from "@utils/adapters/sourceOrigins.adapter";
import { isNonEmptyArray } from "ramda-adjunct";
import { jsonDataLayer, viewItemListSectionFromRoute } from "@utils/googleTagManager";
import { totalAppliedFiltersReducer } from "@utils/filters/totalAppliedFiltersReducer.util";

const applicationsSingleton = ApplicationsSingleton.getInstance();

type TSetIsAppliedProps = {
  field: string;
  id: string | number | { id: string; from: string | number; to: string | number };
  isApplied: boolean;
  multiple: boolean;
};

type TUnApplyWithChildProps = {
  child: IFilter;
  parentId: string | number | { id: string; from: string | number; to: string | number };
  parentField: string;
  newParentId?: string | number | { id: string; from: string | number; to: string | number };
};

type TSearchRenderTypeOption = {
  id: string | number;
  label: string;
  isApplied: boolean;
  parentId?: string | number;
};

type TGetOptionsOnSearchProps = {
  repository: Omit<TFilterRepository, "type" | "fieldAlias"> & { type: string };
  field: string;
  value: string;
  params?: number[] | string[];
};

type TRepositoriesResult = {
  repository: typeof JobsRepository;
  filtersRepository: typeof FiltersRepository;
};

type TUseFilterReturn = {
  totalAppliedFilters: number;
  loading: boolean;
  totalRows: number;
  totalHits: number;
  appliedFilters: TRequestSearch;
  filterSettings: TGlobalFilterSettings;
  device: EDeviceTypes;
  searchResponse: TSearchResponse<TJobRecord>;
  h1?: string | undefined;
  rows: TJobRecord[];
  magnetoUIActiveFilters: TMagnetoUIActiveFilter[];

  setFilters: (payload: TRequestSearch) => Promise<void>;
  setIsApplied: (filter: TSetIsAppliedProps) => Promise<void>;
  clearFilters: () => Promise<void>;
  unApplyWithChild: (withChild: TUnApplyWithChildProps) => Promise<void>;
  getOptionsOnLoad: (field: string, values: (string | number)[]) => Promise<TSearchRenderTypeOption[]>;
  getOptionsOnSearch: (term: TGetOptionsOnSearchProps) => Promise<TSearchRenderTypeOption[]>;
  changeOfferState: (jobId: number | null, view: string, isSaved: boolean | null) => Promise<void>;
  setJobDetailAction: (jobId: number | null, action?: EQueryAction) => Promise<void>;
  applyJobWithAnswer: (answers: TSendAnswers, tagManager?: () => void) => Promise<void>;
  setJobSelected: (record: TJobRecord | null) => void;
};

export const useFilter = (): TUseFilterReturn => {
  const nextRouter = useRouter();
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const { candidate } = useContext(CandidateContext);
  const jobsContext = useContext(JobsContext);
  const landingContext = useContext(LandingContext);
  const { query } = nextRouter;
  const querySearch = getQueyParams("q", query);
  const { landingPage } = landingContext;

  const {
    loading,
    filtersApplied: appliedFilters,
    filterSettings: { filters },
    searchResponse: { counters, totalRows, rows, totalHits },
    selectedJob,
    setContextValue: setJobsContext
  } = jobsContext;

  const { repository, filtersRepository } = useMemo<TRepositoriesResult>(() => {
    if (isAuthenticated) {
      return { repository: JobsWithAuthRepository, filtersRepository: FiltersWithAuthRepository };
    }
    return { repository: JobsRepository, filtersRepository: FiltersRepository };
  }, [isAuthenticated]);

  const magnetoUIActiveFilters = useMemo(() => {
    return normalizeFilter({
      filters,
      counters,
      applied: appliedFilters?.filters,
      t
    });
  }, [filters, counters, appliedFilters?.filters, t]);

  const totalAppliedFilters: number = useMemo(() => {
    return Object.entries(appliedFilters.filters).reduce(totalAppliedFiltersReducer({ filters }), 0);
  }, [appliedFilters.filters, filters]);

  const isJobSlugPage = useMemo<boolean>(() => {
    if (nextRouter.query?.jobSlug) return true;
    return false;
  }, [nextRouter.query?.jobSlug]);

  const sourceOrigins = useMemo<TSourceOrigins>(() => {
    const fMagnetoParam = fMagnetoAdapter(nextRouter.query?.["f_magneto"]);
    const isPublicJobsite = isPublicJobsiteAdapter(landingContext.landingPage);
    return {
      // eslint-disable-next-line camelcase
      f_magneto: fMagnetoParam,
      isPublicJobsite
    };
  }, [nextRouter.query, landingContext]);

  const setSimilarVacancies = async (vacantId: number | null, companyId?: number | null) => {
    const similarVacancies: TSimilarVacancies[] = await repository.getSimilarVacancies(vacantId, companyId);
    setJobsContext((current) => ({ ...current, similarVacancies }));
  };

  const setJobSelected = (record: TJobRecord | null) => {
    setJobsContext((current) => ({
      ...current,
      selectedJob: record,
      offerSuggested: [],
      jobQuestions: null,
      jobDetailAction: null
    }));
    (record && record.id && setSimilarVacancies(record.id, landingPage?.companyId)) || null;
  };

  const setFilters = async (payload: TRequestSearch) => {
    setJobsContext((current) => ({
      ...current,
      filtersApplied: payload,
      selectedJob: null,
      offerSuggested: [],
      jobQuestions: null,
      loading: true
    }));
    try {
      const searchResponse = await repository.search(payload);
      const selectedJob = searchResponse.rows[0] || null;
      const similarVacancies: TSimilarVacancies[] =
        (selectedJob && (await repository.getSimilarVacancies(selectedJob.id, landingPage?.companyId))) || [];

      setJobsContext((current) => ({
        ...current,
        searchResponse,
        selectedJob,
        similarVacancies,
        loading: false
      }));
      isNonEmptyArray(searchResponse.rows) &&
        TagManagerRepository.viewItemList(
          jsonDataLayer(
            searchResponse.rows,
            EViewItemListName.generalOffers.replace(
              "{query}",
              querySearch ? (querySearch as string) : EViewItemListName.enColombia
            ),
            viewItemListSectionFromRoute(nextRouter),
            EEventsGA4.viewItemList,
            { page: payload.paginator.page }
          )
        );
    } catch (error) {
      //TODO: send a informative message
      setJobsContext((current) => ({
        ...current,
        loading: false
      }));
    }

    stateToQueryParams({ ...payload, ...getAllUtms() }, true);
  };

  const setIsApplied = ({ id, field, isApplied, multiple }: TSetIsAppliedProps) => {
    const { filters: currentFilters } = appliedFilters;
    const filterSelected = isApplied
      ? [...currentFilters[field].filter((item) => item !== id)]
      : [...currentFilters[field], id];
    return setFilters({
      ...appliedFilters,
      filters: { ...appliedFilters.filters, [field]: multiple ? filterSelected : isApplied ? [] : [id] },
      paginator: { ...appliedFilters.paginator, page: 1 }
    });
  };

  const clearFilters = async () => {
    if (!totalAppliedFilters) return;
    const emptyFilters: TRequestFilter = {};
    for (const filed in appliedFilters.filters) {
      emptyFilters[filed] = [];
    }
    return setFilters({
      ...appliedFilters,
      filters: emptyFilters,
      paginator: { ...appliedFilters.paginator, page: 1 }
    });
  };

  const unApplyWithChild = ({ child, parentId, parentField, newParentId }: TUnApplyWithChildProps) => {
    const [splitParentId] =
      typeof parentId === "string" && parentId.includes("@") ? parentId.split("@") : [String(parentId)];
    const newChildren: string[] = [];
    child.filtersApplied?.forEach((deepChild) => {
      if (typeof deepChild !== "string") return;
      const [, deepParentId] = deepChild.split("@");
      if (String(deepParentId) !== String(splitParentId)) newChildren.push(deepChild);
    });
    const newParents = newParentId
      ? [...appliedFilters.filters[parentField].filter((item) => item !== parentId), newParentId]
      : [...appliedFilters.filters[parentField].filter((item) => item !== parentId)];
    const newFilters = child.child
      ? { ...appliedFilters.filters, [child.field]: newChildren, [parentField]: newParents, [child.child.field]: [] }
      : { ...appliedFilters.filters, [child.field]: newChildren, [parentField]: newParents };
    return setFilters({
      ...appliedFilters,
      filters: newFilters,
      paginator: { ...appliedFilters.paginator, page: 1 }
    });
  };

  const getOptionsOnLoad = useCallback(
    async (field: string, values: (string | number)[]): Promise<TSearchRenderTypeOption[]> => {
      const labels = await filtersRepository.findLabels({ filters: { [field]: values } }).then((labels) => {
        return labels[field].map((item) => ({ ...item, isApplied: true }));
      });
      return labels;
    },
    [filtersRepository]
  );

  const getOptionsOnSearch = async (term: TGetOptionsOnSearchProps) => {
    const foundOptions = await filtersRepository.findOptions({
      filters: term.params,
      term: term.value,
      field: term.field,
      source: term.repository.source
    });
    return foundOptions.map((opt) => ({ ...opt, isApplied: false }));
  };

  const setJobDetailAction = async (jobId: number | null, action = EQueryAction.apply) => {
    if (!isAuthenticated && jobId) {
      return setJobsContext((current) => ({ ...current, jobDetailAction: action }));
    } else if (!jobId) {
      removeQueryParamByWindow(EQueryParams.action);
      return setJobsContext((current) => ({ ...current, jobQuestions: null, jobDetailAction: null }));
    }
    const offerHasQuestionnaire = selectedJob?.hasPrefilterQuestionnaire;

    if (offerHasQuestionnaire) {
      const jobQuestions: TJobQuestions = await JobsWithAuthRepository.getJobQuestions(jobId);
      return setJobsContext((current) => ({ ...current, jobQuestions, jobDetailAction: EQueryAction.apply }));
    }

    if (applicationsSingleton.hasApplication(jobId)) return;
    applicationsSingleton.addApplication(jobId);
    const suggestedByMatcher = getSuggestedByMatcher(query);
    return JobsWithAuthRepository.applyJob({ jobId, suggestedByMatcher, sourceOrigins }).then((applyResponse) => {
      applicationsSingleton.completeApplication(jobId);
      TagManagerRepository.purchaseV4(
        jsonDataLayer(
          [jobsContext.selectedJob as TJobRecord],
          EViewItemListName.generalOffers.replace(
            "{query}",
            querySearch ? (querySearch as string) : EViewItemListName.enColombia
          ),
          viewItemListSectionFromRoute(nextRouter, !!jobsContext.selectedJob),
          EEventsGA4.purchase,
          { applicationId: applyResponse.data.applicationId },
          candidate || undefined
        )
      );
      if (isJobSlugPage) {
        return repository.getSelectedJob(jobId.toString()).then((selectedJob) => {
          setJobsContext((current) => ({ ...current, selectedJob }));
        });
      }
      return setFilters(appliedFilters);
    });
  };

  const changeOfferState = async (jobId: number | null, view: string, isSaved: boolean | null) => {
    if (!isAuthenticated) {
      setJobDetailAction(jobId, EQueryAction.save);
      return;
    }
    try {
      isSaved ? JobsWithAuthRepository.unsaveJob(jobId as number) : JobsWithAuthRepository.saveJob(jobId as number);
      message.success(t(`jobOffers:message.${isSaved ? "unSaved" : "saved"}`));
    } catch (error) {
      message.error(t("jobOffers:error.message"));
      return;
    }
    try {
      if (view === EVacantViews.row || view === EVacantViews.listMode) {
        setJobsContext((current) => ({ ...current, loading: true }));
        const searchResponse = await repository.search(appliedFilters);
        setJobsContext((current) => ({ ...current, searchResponse, loading: false }));

        if (view === EVacantViews.listMode) {
          const selectOffer = searchResponse.rows.find((row) => row.id === selectedJob?.id) as TJobRecord;
          setJobSelected(selectOffer);
        }
      }

      if (view === EVacantViews.detailPage) {
        const selectOffer = await repository.getSelectedJob(jobId?.toString() as string);
        setJobSelected(selectOffer);
      }
    } catch (error) {
      setJobsContext((current) => ({ ...current, loading: false }));
    }
  };

  const applyJobWithAnswer = async (answers: TSendAnswers) => {
    const suggestedByMatcher = getSuggestedByMatcher(query);
    JobsWithAuthRepository.applyJob({ ...answers, suggestedByMatcher, sourceOrigins }).then((applyResponse) => {
      TagManagerRepository.purchaseV4(
        jsonDataLayer(
          [jobsContext.selectedJob as TJobRecord],
          EViewItemListName.generalOffers.replace(
            "{query}",
            querySearch ? (querySearch as string) : EViewItemListName.enColombia
          ),
          viewItemListSectionFromRoute(nextRouter, !!jobsContext.selectedJob),
          EEventsGA4.purchase,
          { applicationId: applyResponse.data.applicationId },
          candidate || undefined
        )
      );
      if (isJobSlugPage) {
        return repository.getSelectedJob(answers.jobId.toString()).then((selectedJob) => {
          setJobsContext((current) => ({ ...current, selectedJob, jobQuestions: null, jobDetailAction: null }));
        });
      }
      // tagManager && tagManager();
      return setFilters(appliedFilters);
    });
  };

  return {
    totalAppliedFilters,
    loading,
    totalRows,
    totalHits,
    appliedFilters,
    filterSettings: jobsContext.filterSettings,
    device: jobsContext.device,
    searchResponse: jobsContext.searchResponse,
    h1: jobsContext.h1,
    rows,
    magnetoUIActiveFilters,
    setFilters,
    setIsApplied,
    clearFilters,
    unApplyWithChild,
    getOptionsOnLoad,
    getOptionsOnSearch,
    changeOfferState,
    setJobDetailAction,
    applyJobWithAnswer,
    setJobSelected
  };
};
